<template>
    <div id="doctor-news">
        <div class="news-list">
            <div class="empty" v-if="!loading && !dataList.length">-- 已經全部加載 --</div>
            <div class="news-item" v-for="(item, index) in dataList" :key="index">
                <router-link class="category" v-text="`#${item.category}`" :to="`/news/list?CatID=${item.catid}`"></router-link>
                    <h2 class="title" :title="item.title" >
                        <router-link :to="`/news/detail/${item.newsid}`" v-text="item.title"></router-link>
                    </h2>
                <span class="crete-time" v-text="item.createdtime"></span>
            </div>
        </div>
        <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
        <loading v-if="loading" />
    </div>
</template>

<script>
    import Loading from '@/layouts/Loading.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
	import doctor_detail from '@/views/mixins/doctor_detail'
	export default {
        mixins: [doctor_detail],
        props: {
            doctor: null,
        },
        components: {
            Loading,
            SplitPage
        },
        data(){
            return {
                loading: true,
                visible: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataList: [],
            }
        },
        mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            this.onRefresh()
        },
        methods: {
            onRefresh: function(){
                if(this.total > 0 && this.pageNo > 0) {
                    var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                    if(maxPage > 0 && maxPage < this.pageNo) {
                        var path = this.$route.fullPath
                        var hash = this.$route.hash
                        if(hash && path.indexOf(hash) != -1) {
                            path = path.replace(hash, '')
                        }
                        this.$router.push({path: `${path}#page=1`},(e)=>{})
                        return
                    }
                }
                this.loading = true
                this.getDoctorNewsList({
                    globaluserid: this.doctor.globaluserid,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                }, (data, error)=>{
                    this.loading = false
                    if(data) {
                        this.total = data.total
                        this.dataList = data.list
                        this.visible = this.total ? true : false

                        var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                        if(maxPage > 0 && maxPage < this.pageNo) {
                            var path = this.$route.fullPath
                            var hash = this.$route.hash
                            if(hash && path.indexOf(hash) != -1) {
                                path = path.replace(hash, '')
                            }
                            this.$router.push({path: `${path}#page=1`},(e)=>{})
                        }
                    } else {
                        if(error) {
                            this._toast.warning(this, error)
                        }
                    }
                })
            },
            onPageChanged: function(pageNo){
                var path = this.$route.fullPath
                var hash = this.$route.hash
                if(hash && path.indexOf(hash) != -1) {
                    path = path.replace(hash, '')
                }
                this.$router.push({path:  path+'#page='+pageNo},(e)=>{})
            },
        },
        watch: {
            '$route.hash': function(v){
                var hash = this.$route.hash
                hash = hash.replace('#', '')
                var list = hash.split('&')
                var key = list[0].split('=')[0]
                var value = list[0].split('=')[1] / 1
                if(key == 'page') {
                    if(! isNaN(value)) {
                        this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                        this.visible = false
                    }
                }
                this.onRefresh()
            }
        }
    }
</script>

<style lang="scss" scoped>
    #doctor-news {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        padding-bottom: 60px;
    }

    .news-list {
        width: 100%;
        min-height: 580px;
        display: flex;
        flex-direction: column;
    }

    .news-list .empty {
        position: absolute;
        left: 50%;
        top: 50px;
        transform: translateX(-50%);
        font-size: 16px;
        color: #999;
        font-weight: 400;
    }

    .news-list > .news-item {
        height: 58px;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 19px 0px 20px 0px;
        margin: 0px 16px;
        border-bottom: 1px solid #E8E8E8;
        align-items: center;
    }

    .news-list > .news-item .title,
    .news-list > .news-item > .category {
        color: #FF7800;
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
    }

    .news-list > .news-item .title {
        color: #242020;
        flex: 1;
        margin: 0px 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .news-list > .news-item > .create-time {
        line-height: 18px;
        color: #969696;
        font-size: 14px;
        font-weight: 400;
    }
</style>
